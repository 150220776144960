import React, { useEffect, useState } from 'react';
import styles from '../AboutUs/AboutUs.module.css';
import style from "./ProjectDetails.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import { useParams, useLocation, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import Loader from '../../Components/loader/loader';
import { getProjectDetails } from '../../api/homeApi';
import { apiHost, basename } from './../../config/config';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function ProjectDetails() {
    const { pathname } = useLocation();
    const { slug } = useParams();
    const [showLoader, setshowLoader] = useState(true);
    const [singleProject, setsingleProject] = useState();

    console.log(singleProject)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getSingleprojects();
        setshowLoader(false)
        // eslint-disable-next-line
    }, []);

    async function getSingleprojects() {
        await getProjectDetails(slug).then(res => {
            let mysingleProject = res.data.data;
            setsingleProject(mysingleProject);
            setshowLoader(false)
        }).catch(() => {
            setshowLoader(false)
        })
    }

    return (
        <>
            {showLoader && <Loader />}
            {singleProject &&
                <HelmetProvider>
                    <Helmet>
                        {singleProject?.meta_description &&
                            <meta name="description" content={singleProject?.meta_description} />
                        }
                        {singleProject?.meta_keywords &&
                            <meta name="keywords" content={singleProject?.meta_keywords} />
                        }
                    </Helmet>
                </HelmetProvider>
            }
            <section className={style.project_details}>
                <div className='position-relative'>
                    {
                        singleProject?.images[0] ?
                            <div className='headerStyle' style={{ backgroundImage: `url(${apiHost}/${singleProject?.images[0]?.file}` }}>
                                <div className={`position-absolute headerLayer`}></div>
                                <div className={`position-absolute headerSection`}></div>
                            </div>
                            :
                            <div className='headerStyle'></div>}
                </div>
                <div>
                    <div className='wrapper my-5'>
                        <h2 className={style.project_name} >{singleProject?.name}</h2>
                        <div className={`row ms-0 me-0 p-0 ${style.project_info}`}>
                            <div className='col-lg-4 m-0 p-0'>
                                <div className=' mx-3 h-50'>
                                    <p className={`${style.project_subtitle} card-title`}>نمط المشروع</p>
                                    <p className='card-text'>{singleProject?.type?.name ?? ""}</p>
                                </div>
                                <div className=' mx-3 h-50'>
                                    <p className={style.project_subtitle}>المنطقة</p>
                                    <p><span>{singleProject?.city?.name ?? ""}</span> / المملكة العربية السعودية</p>
                                </div>
                            </div>
                            <div className='col-lg-8 m-0 p-0 '>
                                <div className='   m-3  h-100'>
                                    <p className={style.project_subtitle}>لمحة</p>
                                    <div style={{ textAlign: "justify" }}>{singleProject?.description && parse(singleProject?.description)}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            {console.log(singleProject?.images?.length)}
                            <Swiper
                                spaceBetween={10}
                                dir='rtl'
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 2000,
                                    disableOnInteraction: true,
                                }}
                                breakpoints={{
                                    992: {
                                        slidesPerView:  2.5,
                                    },
                                    850: {
                                        slidesPerView: (singleProject?.images?.length <= 2) ? 1 : 1.5,
                                    },
                                    500: {
                                        slidesPerView: 1,
                                    }
                                }}
                            >
                                {singleProject?.images?.map((img, index) => (
                                    <SwiperSlide key={index}>
                                        <img src={`${apiHost}/${img.file}`} className={style.projectImg} alt="project" />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}
