import React, { useEffect, useState } from 'react';
import styles from '../../pages/AboutUs/AboutUs.module.css';
import style from "./Ourwork.module.css";
import { Link, useLocation } from 'react-router-dom';
import { getOurWork } from '../../api/homeApi';
import { basename, apiHost } from '../../config/config';
import moment from 'moment';
import Loader from '../../Components/loader/loader';
import NoData from '../../Components/noData/noData';

export default function Ourwork() {
    const { pathname } = useLocation();
    const [projects, setProjects] = useState([]);
    const [pageImg, setPageImg] = useState("");
    const [showLoader, setshowLoader] = useState(true);
    console.log(projects)
    console.log(pageImg)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getAllprojects();
        moment.updateLocale('ar', {
            months: ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر']
        });
        // eslint-disable-next-line
    }, []);

    async function getAllprojects() {
        await getOurWork().then(res => {
            let myprojects = res?.data?.data?.projects;
            let mypageImg = res?.data?.data?.image;
            setProjects(myprojects);
            setPageImg(mypageImg);
            setshowLoader(false)
            //pagination
            // let total = res.data.pagination.total;
            // setPagecount(Math.ceil(total/9))   
        }).catch(err =>
            setshowLoader(false))
    }

    // pagination
    // const handlepageChange = async data =>{
    //     let currentpage = data.selected+1;
    //     console.log(currentpage);
    //     await axios.get(`${filterBase}projects/all?per_page=15&page=${currentpage}`, {
    //         }).then(
    //             res=>{
    //             setProjects(res.data.data)
    //         }
    //         )
    // }




    return (
        <>
            {showLoader && <Loader />}
            <section className={style.our_workk}>
                <div className='position-relative'>
                    {pageImg ?
                        <div className='headerStyle' style={{ backgroundImage: `url(${apiHost}/${pageImg})` }}>
                            <div className={`position-absolute headerLayer`}></div>
                            <div className={`position-absolute headerSection`}></div>
                        </div>
                        : <div className='headerStyle bg-dark'></div>}
                </div>

                <div className='pagesWrapper mb-5'>
                    <div className='wrapper'>
                        {!showLoader && projects && projects.length === 0 &&
                            <NoData />
                        }
                        <div className='row p-5 me-0' >
                            {projects && projects.map((singleWork, i) => (
                                <div key={i} className={`col-lg-4 col-md-6 pb-2 pe-0 ${style?.singleWork}`} style={{ paddingBottom: "12px", borderRadius:"10px"}}>
                                    <Link  to={`${basename}/our_work/${singleWork.slug}`}>
                                        <div  className='position-relative' style={{ overflow: "hidden" }}>
                                            <div  className={style.ourwork_Img}>
                                                <img src={`${apiHost}/${singleWork.image}`} alt="project" className={style.xx} />
                                                <div className={style.ourwork_overlayer}>
                                                    <div className={style.ourwork_text}>
                                                        {singleWork.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
