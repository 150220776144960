import style from "./Gallery.module.css"
import Masonry from 'react-masonry-component'
import { useLocation } from 'react-router-dom'
import { getGallery } from '../../api/homeApi'
import { apiHost } from './../../config/config'
import React, { useEffect, useState } from 'react'
import Loader from '../../Components/loader/loader'
import NoData from '../../Components/noData/noData'

export default function MyGallery() {

    const [galleryImages, setGalleryImages] = useState([]);
    const [pageImg, setPageImg] = useState("");
    const [showLoader, setshowLoader] = useState(true);
    const [zoomedImage, setZoomedImage] = useState(null);
    const { pathname } = useLocation();
    const masonryOptions = { fitWidth: true, columnWidth: 324, gutter: 8, itemSelector: ".photo_item",    transitionDuration: "0s"}

    const openZoomedImage = (photo) => setZoomedImage(photo);
    const closeZoomedImage = () => setZoomedImage(null);

    useEffect(() => {
        getGallaryData()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    async function getGallaryData() {
        await getGallery().then((res) => {
            let result = res.data.data
            setGalleryImages(result.gallery_photos)
            setPageImg(result.image)
            setshowLoader(false)
        }).catch(() => {
            setshowLoader(false)
        })
    }

    return (
        showLoader ? <Loader />
            : <>
                <div className='position-relative'>
                    {pageImg ?
                        <div className='headerStyle' style={{ backgroundImage: `url(${apiHost}/${pageImg})` }}>
                            <div className={`position-absolute headerLayer`}></div>
                            <div className={`position-absolute headerSection`}></div>
                        </div>
                        : <div className='headerStyle bg-dark'></div>}
                </div>

                <div className='  pt-5'>
                    {!showLoader && galleryImages && galleryImages.length === 0 && <NoData />}
                    <Masonry
                        className={'photo_list'}
                        elementType={'ul'}
                        options={masonryOptions}
                        disableImagesLoaded={false}
                        updateOnEachImageLoad={false}
                        onClick={()=>{console.log(this)}}>
                        {galleryImages && galleryImages.length > 0 && galleryImages.map((photo, i) => (
                            <li key={i} className="photo_item" style={{cursor:"zoom-in"}}>
                                <img src={`${apiHost}/${photo.image}`} alt={photo.name ?? ''} onClick={() => openZoomedImage(`${apiHost}/${photo.image}`)} />
                            </li>
                        ))}
                        {zoomedImage
                            && (<div
                                onClick={closeZoomedImage} className={style.cover}
                                style={{ cursor: "zoom-out", width: "100vw", height: "100vh", background: "#808080c4", position: "fixed", zIndex: 1, top: 0, left: 0, }}>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}  >
                                    <img style={{ maxWidth: "80%", maxHeight: "80%", borderRadius: "20px" }} onClick={closeZoomedImage} src={zoomedImage} alt={zoomedImage} />
                                </div>
                            </div>
                            )}
                    </Masonry>
                </div>
            </>
    )
}
