import axios from 'axios';
import { apiUrl } from '../config/config';

//get blogs
export async function getBlogs(){
    return await axios.get(`${apiUrl}/blogs`);
}
//get single blogs
export async function getBlogDetails(slug){
    return await axios.get(`${apiUrl}/blogs/${slug}`);
}