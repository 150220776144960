import "swiper/css"
import WOW from 'wowjs'
import moment from 'moment'
import "swiper/css/pagination"
import "swiper/css/navigation"
import style from './Home.module.css'
import { getBlogs } from '../../api/blogsApi'
import { Pagination, Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import React, { useEffect, useState } from 'react'
import Loader from '../../Components/loader/loader'
import NoData from '../../Components/noData/noData'
import { getWebsiteSettings } from '../../api/settings'
import { apiHost, basename } from '../../config/config'
import { NavLink, Link, useLocation } from 'react-router-dom'
import { getGallery, getHome, getOurWork } from '../../api/homeApi'
import line from '../../images/line.svg'
import goalIcon from '../../images/1.svg'
import visionIcon from '../../images/2.svg'
import leadershipIcon from '../../images/3.svg'
import clock from '../../images/clock.svg'
import frame from '../../images/Frame.svg'
import frame1 from '../../images/Frame1.svg'

export default function Home() {
  const [blogs, setBlogs] = useState([])
  const [goals, setGoals] = useState([])
  const [social, setSocial] = useState()
  const [gallery, setGallery] = useState({})
  const [projects, setProjects] = useState([])
  const [homeData, sethomeData] = useState('')
  const [showLoader, setshowLoader] = useState(true)
  const [sliderImages, setsliderImages] = useState([])
  const { pathname } = useLocation()
  // const [partners, setPartners] = useState({})
  // const [statistics, setStatistics] = useState([])
  // const [headers, setHeaders] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const wow = new WOW.WOW()
  useEffect(() => {
    wow.init({
      live: false
    })
    wow.sync()
    moment.updateLocale('ar', {
      months: ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر']
    })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getHomeData()
    getGallaryData()
    getAllprojects()
    getAllBlogs()
    getSettings()
    wow.sync()
  }, [])

  async function getHomeData() {
    await getHome().then((res) => {
      let homeData = res.data.data
      sethomeData(homeData)
      setsliderImages(homeData.slider_images)
      setGoals(homeData.goals)
      setshowLoader(false)
      wow.sync()
      // setHeaders(homeData.headers?.home)
      // setStatistics(homeData.statistics)
      // setPartners(homeData.partners)
    }).catch(() => {
      setshowLoader(false)
    })
  }

  async function getGallaryData() {
    await getGallery().then((res) => {
      let galleryData = res.data.data.gallery_photos
      setGallery(galleryData)
      wow.sync()
    }).catch((err) => console.log(err))
  }

  async function getAllprojects() {
    let myprojects = [...projects]
    await getOurWork().then(res => {
      myprojects = res.data.data.projects
      setProjects(myprojects)
      wow.sync()
    })
  }

  async function getSettings() {
    await getWebsiteSettings().then(res => {
      let social = res.data.data.socials
      setSocial(social)
    })
  }

  async function getAllBlogs() {
    let myblogs = [...blogs]
    await getBlogs().then(res => {
      myblogs = res.data.data.blogs
      setBlogs(myblogs)
      wow.sync()
    })
  }


  const timeFormate = (time) => {
    let formattedTime
    const createdAt = moment(time.created_at)
    const isMoreThan24Hours = moment().diff(createdAt, 'hours') > 24
    return formattedTime = createdAt.format(isMoreThan24Hours ? 'DD MMMM YYYY' : 'hh:mm A')
  }

  // function downloadPDF(fileURL) {
  //   const link = document.createElement('a');
  //   link.href = fileURL;
  //   link.download = 'file.pdf';
  //   link.target = '_blank';
  //   link.click();
  // }


  return (
    <>
      {showLoader && <Loader />}
      <section className={`position-relative ${style.home}`}>

        {/** home slider banner **/}
        <Swiper dir="rtl" modules={[Pagination, Autoplay]} className="mySwiper"
          autoplay={{
            delay: 2500,
            disableOnInteraction: true
          }}
          pagination={{
            clickable: true
          }}>
          {sliderImages?.length > 0 ? sliderImages.map((slide) => (
            <SwiperSlide key={slide.slug}>
              <div className={`${style.landScape} d-flex align-items-center justify-content-center`} style={{ backgroundImage: `url(${apiHost}/${slide.image})` }}>
                <div className={`position-absolute headerLayer h-100`}></div>
                <div className='wrapper'>
                  <div className={`d-flex flex-column justify-content-center align-content-center ${style.landscapeTitle}`}>
                    <h1 className='text-center'>{slide.title}</h1>
                    <p className='text-center'>{slide.description}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))
            : <div className={`${style.landScape} bg-dark`}></div>}
          <SwiperSlide>
            <div className={`${style.landScape} d-flex align-items-center justify-content-center`} style={{ backgroundImage: `url(./1.jpg)` }}>
              <div className={`position-absolute headerLayer h-100`}></div>
              <div className='wrapper'>
                <div className={`d-flex flex-column justify-content-center align-content-center ${style.landscapeTitle}`}>
                  <h1 className='text-center'>{"slide.title"}</h1>
                  <p className='text-center'>{"slide.description"}</p>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/*Social*/}
          <div className={`${style.homeSocial}`}>
            <img src={line} alt="line" />
            {social?.length > 0 && social.map((media) => {
              return (
                <a className={`${style.socialLink} `} key={media.slug} href={media.link}>
                  <img
                    style={{ width: "100%" }}
                    src={`${apiHost}/${media.image}`}
                    alt={media.name} />
                </a>)
            }
            )
            }
            <img src={line} alt="line" />
          </div>
        </Swiper>
        {!showLoader && homeData.slider_images && homeData.slider_images.length === 0 && homeData.slider_images && homeData.partners.length === 0
          && homeData.slider_images && homeData.statistics.length === 0 && homeData.slider_images && homeData.goals.length === 0 &&
          <NoData />
        }
        {/** About us section **/}
        {goals?.length > 0 &&
          <div className={style.about_us}>
            <div className=' m-auto'>
              <h2 className='text-center wow animate__fadeInDown ' data-wow-duration='1s'>من نحن</h2>
              <div className={`${style.about_us_cards} align-items-center flex-column flex-lg-row d-flex justify-content-between`}>
                {goals?.slice(0, 3)?.map((goal, index) => (
                  <div key={index} style={{ backgroundImage: `url(${apiHost}/${goal.image})` }} className={`${style.card_content} d-flex flex-column align-items-center wow animate__fadeInUpBig`} data-wow-duration='1s'>
                    <div className={style.card_container}>
                      <img className={style.icon} src={goal.title === 'أهدافنا' ? goalIcon : goal.title === 'رسالتنا' ? leadershipIcon : visionIcon} alt="" />
                      <h5>{goal.title}</h5>
                      <p className='mb-0 text-center p-2'>{goal.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        {/** Gallery section **/}
        {gallery?.length > 0 &&
          <div className={`${style.gallery} m-auto`}>
            <h2 className='text-center wow animate__fadeInDown' data-wow-duration='1s'>معرض الصور</h2>
            <Swiper
              dir='rtl'
              slidesPerView={gallery?.length > 5 ? 5.5 : gallery?.length}
              spaceBetween={5}
              modules={[Pagination]}
              breakpoints={{
                992: { slidesPerView: gallery?.length > 5 ? 5.5 : gallery?.length, spaceBetween: 5 },
                600: { slidesPerView: 3, spaceBetween: 10 },
                200: { slidesPerView: 1, spaceBetween: 10 }
              }} >
              {gallery?.length > 0 && gallery.slice(0, 10).map((photo) => (
                <SwiperSlide key={photo.id} className={style.swiper_imgWrapper}>
                  <Link to={`${basename}/gallery`}>
                    <div className={style.gallery_wrapper}>
                      <img key={photo.slug} src={`${apiHost}/${photo.image}`} alt="" className='w-100 p-1' />
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
            {gallery?.length > 0 && <NavLink to="gallery">
              <button className='mt-5' >المزيد</button>
            </NavLink>}
          </div>
        }
        {/** Our work section **/}
        {(projects?.length > 0) &&
          <div className={`${style.our_work}`}>
            <div className='wrapper m-auto'>
              <h2 className='text-center wow animate__fadeInDown  m-0 ' data-wow-duration='1s'>أخر الأعمال </h2>
              <div className={style.mywork_parent}>
                <Swiper
                  dir='rtl'
                  slidesPerView={3}
                  spaceBetween={10}
                  // modules={[Pagination, Navigation]}
                  // pagination={{ clickable: true }}
                  className=""
                  breakpoints={{
                    992: { slidesPerView: 3, spaceBetween: 10 },
                    750: { slidesPerView: 2, spaceBetween: 15 },
                    200: { slidesPerView: 1, spaceBetween: 10 }
                  }} >
                  {projects?.map((project) => (
                    <SwiperSlide key={project.id} style={{ width: "580px", height: "580px" }}>
                      <Link to={`${basename}/our_work/${project.slug}`} className={`${style.swiper_imgWrapper} d-flex align-items-start`} >
                        <div className={style.ourwork_img}>
                        <img src={`${apiHost}/${project?.image}`} className={`h-100 ${style.ourwork_img}`} alt={project.name} />

                        <div className={style.ourwork_overlayer} >
                          <p className={style.ourwork_text}>{project?.name}</p>
                        </div>
                        </div>
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        }
        {/** Latest News section **/}
        {(blogs?.length > 0) &&
          <div className={style.latest_news}>
            <div className='wrapper m-auto'>
              <h2 className='text-center wow animate__fadeInDown' data-wow-duration='1s'>أخر الأخبار</h2>
              <div>
                <Swiper dir='rtl' slidesPerView={3} spaceBetween={10}
                  breakpoints={{
                    992: { slidesPerView: (3), spaceBetween: 10 },
                    750: { slidesPerView: 2, spaceBetween: 15 },
                    200: { slidesPerView: 1, spaceBetween: 10 }
                  }} >
                  {blogs?.map((oneNew, index) => {
                    return (
                      <SwiperSlide key={index} className={style.swiper_imgWrapper}>
                        <Link to={`${basename}/latest-news/${oneNew.slug}`}>
                          <div className={style.news_card}>
                            <img src={`${apiHost}/${oneNew.images}`} className="w-100 h-100" alt={oneNew.title} />
                            <div className={style.blogDataWrapper}>
                              <p>{oneNew.title.substring(0, 70)} {oneNew.title.length > 70 ? '...' : ''}</p>
                              <span><img style={{ width: "20px", height: "20px", margin: "5px" }} src={clock} alt="clock" />{timeFormate(oneNew)}</span>
                            </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
              {blogs?.length > 3 && <NavLink to={`${basename}/latest-news`}>
                <button>المزيد</button>
              </NavLink>}
            </div>
            {/** Our work section **/}
            {
              <div className={`${style.our_work} pb-5`}>
                <div className='wrapper m-auto'>
                  <h2 className='text-center wow animate__fadeInDown  m-0' data-wow-duration='1s'>فروعنا </h2>
                  <div className={`${style.mywork_parent} align-items-center`}>
                    <Swiper
                      dir='rtl'
                      slidesPerView={3}
                      spaceBetween={10}
                      // modules={[Pagination, Navigation]}
                      // pagination={{ clickable: true }}
                      className="align-items-center"
                      breakpoints={{
                        992: { slidesPerView: 3, spaceBetween: 10 },
                        750: { slidesPerView: 2, spaceBetween: 15 },
                        200: { slidesPerView: 1, spaceBetween: 10 }
                      }} >
                      <SwiperSlide style={{ width: "580px", height: "580" }}>
                        <Link to="" className={`${style.swiper_imgWrapper} d-flex align-items-start`} >
                          <div className={style.ourwork_img}>

                          <img className={` h-100 ${style.ourwork_img}`} src={`./1ش.jpg`} alt="" />
                          <div className={`${style.ourwork_overlayer} bg-light`} >
                            <p className={`${style.ourwork_text} text-dark`}>{"مجموعة ياسر المحمادي"}</p>
                          </div>
                          </div>
                        </Link>
                      </SwiperSlide>
                      <SwiperSlide style={{ width: "580px", height: "580" }}>
                        <Link to="" className={`${style.swiper_imgWrapper} d-flex align-items-start`} >
                          <div className={style.ourwork_img}>

                          <img className={` h-100 ${style.ourwork_img}`} src={`./1س.jpg`}  alt="" />
                          <div className={`${style.ourwork_overlayer} bg-light`} >
                            <p className={`${style.ourwork_text} text-dark`}>
                              {"مساكن يارا"}
                            </p>
                          </div>
                          </div>
                        </Link>
                      </SwiperSlide>


                      <SwiperSlide style={{ width: "580px", height: "580" }}>
                        <Link to="" className={`${style.swiper_imgWrapper} d-flex align-items-start`} >
                          <div className={style.ourwork_img}>

                          <img className={` h-100 ${style.ourwork_img}`} src={`./1ي.jpg`}  alt="" />
                          <div className={`${style.ourwork_overlayer} bg-light`} >
                            <p className={`${style.ourwork_text} text-dark`}>{"شركة الانشاءات العمرانية"}</p>
                          </div>
                          </div>
                        </Link>

                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            }
          </div>

        }
      </section>
      {/* <footer>
        <div className='main_footer d-flex justify-content-evenly flex-column align-items-center footer_md text-light ' style={{ backgroundImage: "url(/bg.png)" }} >
          <div className="cover"></div>
          <h2 className='p-3' style={{ fontSize: "48px", fontWeight: "700" }}>الإنجازات</h2>
          <div className='d-flex justify-content-evenly  flex-column flex-lg-row p-3 pb-5 w-75'>
            <div className='d-flex justify-content-center align-items-center'>
              <img src={frame} alt="frame" className="h-75" />
              <div className='d-flex flex-column justify-content-center p-3'>
                <h3 style={{ fontSize: "35px" }} >2500</h3>
                <h3 style={{ width: "max-content", fontSize: "25px" }}>المشاريع المنجزة</h3>
              </div>
            </div>
            <div className='d-flex justify-content-center align-items-end'>
              <img src={frame1} alt="frame" className="h-75" />
              <div className='d-flex flex-column justify-content-center px-3'>
                <h3 style={{ fontSize: "35px" }}>300</h3>
                <h3 style={{ width: "max-content", fontSize: "25px" }}>خدمات الصناعات</h3>
              </div>
            </div>
          </div>
        </div>
      </footer> */}
    </>
  )
}
