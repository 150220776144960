import React, { useEffect, useState } from 'react';
import styles from '../AboutUs/AboutUs.module.css';
import style from './Contactus.module.css';
import { Link } from 'react-router-dom';
import { getContactUs, sendContactMsg } from '../../api/contactUsAPi';
import { apiHost, basename } from './../../config/config';
import { getWebsiteSettings } from '../../api/settings';
import Swal from 'sweetalert2';
import Loader from '../../Components/loader/loader';
import mail from '../../images/mail.svg';
import call from '../../images/contact.svg';
import adress from '../../images/adress.svg';

export default function Contactus() {
    const [websiteSettings, setwebsiteSettings] = useState({});
    const [contactHeaders, setContactHeaders] = useState({})
    const [contactMsg, setcontactMsg] = useState({})
    const [errors, setErrors] = useState({});
    const [loading, setloading] = useState(false);
    const [showLoader, setshowLoader] = useState(true);

    useEffect(() => {
        getContact()
        getSetting()
        // eslint-disable-next-line
    }, [])
    
    async function getContact() {
        try {
            const res = await getContactUs()
            let result = res.data.data
            setContactHeaders(result)
            setshowLoader(false)
        } catch (err) {
            console.log(err);
            setshowLoader(false)
        }
    }

    async function getSetting() {
        try {
            const res = await getWebsiteSettings()
            let settings = { ...websiteSettings }
            settings = res.data.data;
            setwebsiteSettings(settings);
        } catch (err) {
            console.log(err);
        }
    }

    function msgHandler(e) {
        let msg = { ...contactMsg }
        msg[e.target.name] = e.target.value;
        setcontactMsg(msg)

        let err = { ...errors }
        err[e.target.name] = ''
        setErrors(err)
    }

    async function submitContact(e) {
        e.preventDefault();
        setloading(true)
        try {
            await sendContactMsg(contactMsg)
            setloading(false)
            Swal.fire({
                icon: 'success',
                text: 'تم الارسال بنجاح',
                showConfirmButton: false,
                timer: 1500
            })
            await document.getElementById('contactForm').reset();
            setErrors('')

        } catch (error) {
            setloading(false)
            let err = { ...errors }
            Object.entries(error.response.data.data).map(([key, value]) => {
                err[key] = value;
                setErrors(err);
                return true;
            });
        }
    }
    
    return (
        <>
            {showLoader && <Loader />}
            <section style={{ backgroundColor: "#fff" }}>
                <div className='position-relative'>
                    {contactHeaders?.image ?
                        <div className='headerStyle' style={{ backgroundImage: `url(${apiHost}/${contactHeaders?.image})` }}>
                            <div className={`position-absolute headerLayer`}></div>
                            <div className={`position-absolute headerSection`}></div>
                        </div>
                        : <div className='headerStyle bg-dark'></div>}
                </div>

                <div>
                    <div className='wrapper pb-5'>
                        {/* <div>
                            <h2 className={`text-center ${style.contactus_header}`}>{contactHeaders?.title}</h2>
                            <p className={`text-center ${style.contactus_subheader}`}>{contactHeaders?.description}</p>
                        </div> */}

                        <div className={`row pt-5 ${style.contactus_form}`}>
                            <div className={`col-lg-4 d-flex flex-column justify-content-between`}>
                                <div className={`py-5 ${style.contactinfo_wrapper}`}>
                                    <div className='d-flex align-items-center flex-column'>
                                        <img className='m-2' src={mail} alt="mail" />
                                        <div className={`me-3 ms-3 ${style.contact_info}`}>
                                            <p className={style.contactName}>البريد الالكتروني</p>
                                            <a className='mb-0' href={`mailto:${websiteSettings?.email}`}>{websiteSettings?.email}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className={`py-5 ${style.contactinfo_wrapper}`}>
                                    <div className='d-flex align-items-center flex-column'>
                                        <img className='m-2' src={call} alt="" />
                                        <div className={`me-3 ms-3  ${style.contact_info}`}>
                                            <p className={style.contactName}>رقم الفاكس</p>
                                            <a href={`tel:${websiteSettings?.phone}`}>{websiteSettings?.phone}</a>
                                        </div>
                                    </div>
                                </div>
                                <div className={`py-5 ${style.contactinfo_wrapper}`}>
                                    <div className='d-flex align-items-center flex-column'>
                                        <img className='m-2' src={adress} alt="" />
                                        <div className={`me-3 ms-3 ${style.contact_info}`}>
                                            <p className={style.contactName}>العنوان</p>
                                            <p className={`mb-0 ${style.contactAddress}`}>{websiteSettings?.address}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form className={`row col-lg-8 h-100 ${style.contactform}`} id='contactForm' onSubmit={submitContact}>
                                <h2>ابقَ على تواصل</h2>
                                <div className='d-flex flex-column mb-4 col-lg-6'>
                                    <input onChange={msgHandler} placeholder='الاسم' type="text" name="name" className={style.contact_input} required />
                                    <div className='text-danger'>{errors.name}</div>
                                </div>
                                <div className='d-flex flex-column mb-4 col-lg-6'>
                                    <input onChange={msgHandler} placeholder='رقم الهاتف' type="text" name="phone" className={style.contact_input} required />
                                    <div className='text-danger'>{errors.phone}</div>
                                </div>
                                <div className='d-flex flex-column mb-4 col-lg-12'>
                                    <input onChange={msgHandler} placeholder='البريد الالكتروني' type="email" name="email" className={`${style.contact_input}`} required />
                                    <div className='text-danger'>{errors.email}</div>
                                </div>
                                <div className='col-lg-12 '>
                                    <div className='d-flex flex-column'>
                                        <textarea onChange={msgHandler} placeholder='رسالتك' name='message' required />
                                        <div className='text-danger'>{errors.message}</div>

                                        {!loading ?
                                            <button type='submit' className={`${style.form_submit} d-block my-4 w-25`} >ارسال</button>
                                            : <button className={`${style.form_submit} d-block d-lg-none mb-4`}>
                                                جاري الارسال
                                                <span className="spinner-border text-light spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            </button>}
                                        <div className='d-flex flex-column'>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>


                        {/* <div className='row m-0 p-0 d-none d-lg-block'>
                        <div className='col-8'>
                            <div className='w-50 m-auto mt-4'>
                                <button type='submit' className={style.form_submit}>ارسال</button>
                            </div>
                        </div>
                    </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}
