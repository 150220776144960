// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n@keyframes Gallery_fade-in__ogfka {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n  \n  .Gallery_cover__TamKI {\n    animation: Gallery_fade-in__ogfka .5s ease-in;\n}\n  ", "",{"version":3,"sources":["webpack://./src/pages/Gallery/Gallery.module.css"],"names":[],"mappings":";AACA;IACI;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF;;EAEA;IACE,6CAA8B;AAClC","sourcesContent":["\n@keyframes fade-in {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n  \n  .cover {\n    animation: fade-in .5s ease-in;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cover": "Gallery_cover__TamKI",
	"fade-in": "Gallery_fade-in__ogfka"
};
export default ___CSS_LOADER_EXPORT___;
