import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import translationEn from '../src/localization/en.json'
import translationAr from '../src/localization/ar.json'
import LanguageDetector  from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: translationEn
    },
    ar: {
        translation: translationAr
    }
};

i18next
    .use(LanguageDetector)
    .use(initReactI18next) 
    .init({
        resources,
        lng: /* localStorage. getItem('i18nextLng') ||  */'ar',
        fallbackLng: ["ar","en"],
        interpolation: {
        escapeValue: false 
        },
        react:{
            useSuspense:false
        }
    });

export default i18next;