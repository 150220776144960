import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import parse from "html-react-parser";
import style from "./AboutUs.module.css";
import star from "../../images/star.svg";
import { useLocation } from "react-router-dom";
import { apiHost } from "./../../config/config";
import { getAboutUs } from "../../api/aboutusApi";
import React, { useState, useEffect } from "react";
import Loader from "../../Components/loader/loader";
import bg from "../../images/bg5.png"
export default function AboutUs() {
  const [abouData, setAboutData] = useState();
  const [showLoader, setshowLoader] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    getAboutData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  async function getAboutData() {
    await getAboutUs()
      .then((res) => {
        let aboutus = res.data.data;
        setAboutData(aboutus);
        setshowLoader(false);
      })
      .catch(() => {
        setshowLoader(false);
      });
  }

  return (
    <>
      {showLoader && <Loader />}
      <section className="pagesWrapper justify">
        <div className="position-relative">
          {abouData?.image
            ? <div className="headerStyle" style={{ backgroundImage: `url(${apiHost}/${abouData?.image})` }}>
              <div className={`position-absolute headerLayer`}></div>
              <div className={`position-absolute headerSection`}></div>
            </div>
            : <div className="headerStyle bg-dark"></div>}
        </div>

        {abouData ?
          <div className={`${style.topContainer}`} style={{ backgroundImage: `url(${bg})` }} >
            <img className={`${style.yasser} rounded`} src="./yasserr.png" alt="Yasser" />
            <div className={` ${style.about_text} m-0 p-0`}>
              <p className={`${style.about_content} m-0  `}>{parse(abouData?.about_us_description)}</p>
            </div>
            <div className={`${style.topContainerBg}`}></div>
          </div>
          : <div className="p-5"></div>
        }


        <div className="mb-lg-5">
          <div className="wrapper ">
            <div className={`${style.about_text} w-100 row m-0 ps-0 pe-0 d-flex justify-content-between align-items-center`}>
              <div className="col-lg-4 ps-0 pe-0">
                {abouData?.logo_dark && (
                  <img
                    className={`${style.aboutus_logo} d-none d-lg-block`}
                    src={`${apiHost}/${abouData?.logo_dark}`}
                    alt="logo"
                  />
                )}
              </div>
              <div className="col-lg-8 ps-0 pe-0">
                <div className={`${style.about_vision}`}>
                  <p className={style.about_visionTitle}>الرؤية</p>
                  <p>{abouData?.vision}</p>
                  <p className={style.about_visionTitle}>الرسالة</p>
                  <p>{abouData?.message}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper d-flex flex-column p-lg-5 mt-lg-5">
          <h2 className={style.markHeader}>ما الذي يميزنا؟</h2>
          {abouData?.advantages?.length > 0 &&
            abouData?.advantages?.map((feature) => {
              return (
                <div
                  className="d-flex align-items-center m-1"
                  key={feature.slug}
                >
                  <img className={` ${style.markImg}`} src={star} alt="star" />
                  <p className={`pe-2 pb-0 mb-0 ${style.markCintent}`}>
                    {feature?.description}
                  </p>
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
}
