import axios from 'axios';
import { apiUrl } from '../config/config';

//get home
export async function getHome(){
    return await axios.get(`${apiUrl}/home`);
}
//get image gallery
export async function getGallery(){
    return await axios.get(`${apiUrl}/photos-gallery`);
}
//get our work
export async function getOurWork(){
    return await axios.get(`${apiUrl}/projects`);
}
//get  work details
export async function getProjectDetails(slug){
    return await axios.get(`${apiUrl}/projects/${slug}`);
}