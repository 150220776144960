import React, { useEffect, useState } from 'react';
import style from "./LatestNews.module.css";
import styles from '../../pages/AboutUs/AboutUs.module.css';
import { Link, useLocation } from 'react-router-dom';
import { apiHost, basename } from '../../config/config';
import { getBlogs } from '../../api/blogsApi';
import moment from 'moment';
import Loader from '../../Components/loader/loader';
import NoData from '../../Components/noData/noData';
import clock from '../../images/clock.svg';

export default function LatestNews() {
    // const { t, i18n } = useTranslation();
    // const [pageCount , setPagecount] = useState(0);
    // const filterBase = `${process.env.REACT_APP_API}/blogs`;
    const { pathname } = useLocation();
    const [showLoader, setshowLoader] = useState(true);
    const [blogs, setBlogs] = useState(["./bg.png", "./bg.png", "./bg.png", "./bg.png", "./bg.png", "./bg.png",]);
    const [pageImg, setPageImg] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getAllblogs();
        // eslint-disable-next-line
    }, []);

    async function getAllblogs() {
         
        await getBlogs().then(res => {
            console.log(res);
            let myblogs = res.data.data.blogs;
            let mypageImg = res.data.data.image;
            setBlogs(myblogs);
            setPageImg(mypageImg);
            setshowLoader(false)
            //pagination
            // let total = res.data.pagination.total;
            // setPagecount(Math.ceil(total/9))   
        }).catch(() => {
            setshowLoader(false)
        })
    }

    // pagination
    // const handlepageChange = async data =>{
    //     let currentpage = data.selected+1;
    //     console.log(currentpage);
    //     await axios.get(`${filterBase}blogs/all?per_page=15&page=${currentpage}`, {
    //         }).then(
    //             res=>{
    //             setBlogs(res.data.data)
    //         }
    //         )
    // }


    return (
        <>
            {showLoader && <Loader />}
            <section className={style.news}>
                <div className='position-relative'>
                    {pageImg ?
                        <div className='headerStyle' style={{ backgroundImage: `url(${apiHost}/${pageImg})` }}>
                            <div className={`position-absolute headerLayer`}></div>
                            <div className={`position-absolute headerSection`}></div>
                        </div>
                        : <div className='headerStyle '></div>}
                </div>

                <div className='pagesWrapper mb-5'>
                    <div className='wrapper'>
                        {!showLoader && blogs && blogs.length === 0 &&
                            <NoData />
                        }
                        <div className={`${style.latest_news} row p-5 me-0`}>
                            {blogs && blogs.map((singleNew, i) => {
                                const createdAt = moment(singleNew.created_at)
                                const isMoreThan24Hours = moment().diff(createdAt, 'hours') > 24
                                const formattedTime = createdAt.format(isMoreThan24Hours ? 'DD MMMM YYYY' : 'hh:mm A')
                                return (
                                    <div key={i} className={`col-lg-4 col-md-6 pb-2 pe-0 ${style.news_card}`}>
                                        <Link to={`${basename}/latest-news/${singleNew.slug}`}>

                                            <img src={`${apiHost}/${singleNew?.images}`} className="w-100" alt={singleNew?.title?.substring(0, 20)} />
                                            <div className={style.blogDataWrapper}>
                                                <p>{singleNew?.title?.substring(0, 70)}</p>
                                                <span><img style={{ width: "20px", height: "20px", margin: "5px" }} src={clock} alt="clock" />{formattedTime}</span>
                                            </div>
                                        </Link>

                                    </div>

                                )
                            })}
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}
