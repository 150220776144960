import React, { useState, useEffect } from 'react';
import style from './Navbar.module.css';
import { NavLink } from 'react-router-dom';
import { getWebsiteSettings } from '../../api/settings';
import { apiHost, basename } from './../../config/config';
import logo from '../../images/logo.svg';

export default function Navbar() {

    const [showNav, setShowNav] = useState(false);
    const [websiteSettings, setwebsiteSettings] = useState({});

    console.log(websiteSettings)

    useEffect(() => {
        getSettingData()
        // eslint-disable-next-line
    }, []);

    async function getSettingData() {
        await getWebsiteSettings().then((res) => {
            let settings = { ...websiteSettings }
            settings = res.data.data;
            setwebsiteSettings(settings);
        }).catch((err) => console.log(err))
    }

    const toggleNav = () => {
        setShowNav(!showNav)
    }

    return (
        <React.Fragment>
            <nav className={`${style.navbarContainer} position-absolute w-100 top-0 py-0 navbar navbar-expand-lg`}>
                <div className={`${style.navSubContainer} d-flex justify-content-between align-items-center `}>
                    <div className={`${style.logoContainer} navbar-brand`}>
                        {websiteSettings && websiteSettings.logo_light &&
                            <NavLink to={`${basename}/`}>
                                <img alt='Yasser for Contracting Logo' src={`${apiHost}/${websiteSettings.logo_light}`} className={`${style.navbarLogo}`} />
                            </NavLink>
                        }
                    </div>
                    <button onClick={toggleNav} className={`${style.toggler} d-flex .custom-toggler.navbar-toggler navbar-toggler`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <p className='mx-2 text-light'>
                        القائمة الرئيسية

                        </p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill='#fff' d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" /></svg>
                    </button>
                    <div className={`collapse navbar-collapse  ${showNav ? "d-block" : "d-none"}`} id="navbarSupportedContent">
                        <ul className={`${style.navLinksContainer} navbar-nav w-100 d-flex justify-content-center`}>
                            <li className={`nav-item ${style.collapsedLinks}`}>
                                <NavLink className={`nav-link`} to={`${basename}`}>الرئيسية</NavLink>
                            </li>
                            <li className={`nav-item ${style.collapsedLinks}`}>
                                <NavLink className={`nav-link`} to={`${basename}/about-us`}>من نحن
                                </NavLink>
                            </li>
                            <li className={`nav-item ${style.collapsedLinks}`}>
                                <NavLink className={`nav-link`} to={`${basename}/our-work`}>أعمالنا</NavLink>
                            </li>
                            <li className={`nav-item ${style.collapsedLinks}`}>
                                <NavLink className={`nav-link`} to={`${basename}/latest-news`}>اخر الأخبار</NavLink>
                            </li>
                            <li className={`nav-item ${style.collapsedLinks}`}>
                                <NavLink className={`nav-link`} to={`${basename}/gallery`}>معرض الصور</NavLink>
                            </li>
                            <li className={`nav-item ${style.collapsedLinks}`}>
                                <NavLink className={`nav-link`} to={`${basename}/contact_us`}>اتصل بنا</NavLink>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
        </React.Fragment>
    )
}
