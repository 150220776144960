import axios from 'axios';
import { apiUrl } from '../config/config';

//get Contactus
export async function getContactUs(){
    return await axios.get(`${apiUrl}/contact-us`);
}
//add Contactus
export async function sendContactMsg(data){
    return await axios.post(`${apiUrl}/contact-us`,data);
}