import React, { useEffect, useState } from 'react';
import styles from '../../pages/AboutUs/AboutUs.module.css';
import style from "./NewDetails.module.css";
import { Link, useLocation, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import parse from 'html-react-parser';
import { getBlogDetails } from '../../api/blogsApi';
import { apiHost } from '../../config/config';
import { Navigation } from "swiper";
import Loader from '../../Components/loader/loader';
import bg from '../../images/laura-davidson-QBAH4IldaZY-unsplash.jpg';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function NewDetails() {
    const { pathname } = useLocation();
    const { slug } = useParams();
    const [showLoader, setshowLoader] = useState(true);
    const [singleNew, setsingleNew] = useState();

    console.log(singleNew)
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        getSingleNews();
        // eslint-disable-next-line
    }, []);

    async function getSingleNews() {
        await getBlogDetails(slug).then(res => {
            let mysingleNew = res.data.data;
            setsingleNew(mysingleNew);
            setshowLoader(false)
        }).catch(() => {
            setshowLoader(false)
        })
    }

    return (
        <>
            {showLoader && <Loader />}
            {singleNew &&
                <HelmetProvider>
                    <Helmet>
                        {singleNew.meta_description &&
                            <meta name="description" content={singleNew?.meta_description} />
                        }
                        {singleNew.meta_keywords &&
                            <meta name="keywords" content={singleNew?.meta_keywords} />
                        }
                    </Helmet>
                </HelmetProvider>
            }
            <section className={style.new_details}>
                <div className='position-relative'>
                    {singleNew?.images[0] ?
                        <div className='headerStyle' style={{backgroundImage: `url(${apiHost}/${singleNew?.images[0]?.file}`}} >
                            <div className={`position-absolute headerLayer`}></div>
                            <div className={`position-absolute headerSection`}></div>
                        </div>
                        : <div className='headerStyle'></div>}
                </div>
                <div className='pagesWrapper my-5'>
                    {/* <div className='wrapper'> */}
                        {/* { */}
                            <div className={`${style.newsDetailsWrapper} p-0 p-lg-5`}>
                                <div className={style.imgWrapper}>
                                    <Swiper
                                    className='w-100'
                                        slidesPerView={1}
                                        spaceBetween={10}
                                        dir='rtl'
                                        navigation={true}
                                        modules={[Navigation]}
                                    >
                                        {singleNew?.images?.map((img, index) => (
                                            <SwiperSlide key={index}>
                                                <img src={`${apiHost}/${img?.file}`} className={`w-100 ${style.newsImg}`} alt="project" />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                                <h5>{singleNew?.title}</h5>
                                <div style={{ textAlign: "justify" }}>
                                    {singleNew?.description ? parse(singleNew?.description) : ""}
                                </div>
                            {/* </div> */}
                        {/* // } */}
                    </div>
                </div>
            </section>
        </>
    )
}
