import React, { useEffect, useState } from 'react';
import dubiLogo from "../../images/dubi.png"
import logo from '../../images/logo.svg'
import call from '../../images/call.svg'
// import { NavLink } from 'react-router-dom';
import { getWebsiteSettings } from '../../api/settings';
import { apiHost } from '../../config/config';
// import { basename, apiHost } from './../../config/config';

export default function Footer() {

  const [websiteSettings, setwebsiteSettings] = useState({});
  console.log(websiteSettings);
  useEffect(() => {
    getWebsiteSettings().then((res) => {
      let settings = res.data.data;
      setwebsiteSettings(settings);
    })
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <footer className='main_footer  '>
        <div style={{ background: "#A01D21", padding: "70px 44px 50px", color: "white", textAlign: "start", lineHeight: "40px" }} className='d-flex justify-content-start flex-lg-row flex-column '>
          <div className='d-flex justify-content-center'>
            <img className='footer_logo mx-0 ms-lg-5' src={logo} alt="logo" />
          </div>
          <div className=' footer-description-container  d-flex flex-column justify-content-between  mb-lg-0 mb-4 mx-0 mx-lg-5'>
            <span className='footer-description  ms-lg-5 ' >{websiteSettings?.meta_description} </span>
            <div className={"homeSocial"}>
              {websiteSettings?.socials?.length > 0 && websiteSettings?.socials?.map((media) => {
                return (
                  <a className={`socialLink`} key={media.slug} href={media.link}>
                    <img
                      style={{ width: "100%" }}
                      src={`${apiHost}/${media.image}`}
                      alt={media.name} />
                  </a>)
              }
              )
              }
            </div>
          </div>
          <div className='d-flex flex-column align-items-start mx-0 mx-lg-5'>
            <span className='fw-bolder'>{websiteSettings?.title}</span>
            <span className=''>{websiteSettings?.address}</span>
            <button className='callBtn  '>
              <img className='mx-2' src={call} alt="call" />
              <a href={`tel:${websiteSettings?.phone}`}>{websiteSettings?.phone}</a>
            </button>
          </div>
        </div>
        <div dir="ltr" style={{ backgroundColor: "#98191D", textAlign: "center", padding: "15px", fontSize: "18px", LineHeight: "21px" }}>
          <span style={{ fontSize: "18px", color: "#fff", fontWeight: "100", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span style={{ direction: "ltr" }}>
              &copy; 2022 KB Powered by
              <a href='https://dubisign.ae' target='_blank' rel="noreferrer" className='me-2' style={{ color: "#fff", textDecoration: "none", fontWeight: "800" }}> DUBISIGN</a>
              <img src={dubiLogo} style={{ width: "25px" }} alt="dubiLogo" />
            </span>
          </span>
        </div>
      </footer>
    </>
  )
}
