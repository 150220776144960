import React from 'react'

export default function NoData() {
  return (
    <div className='pagesWrapper'>
        <div className='wrapper text-center d-flex justify-content-center align-items-center'>
            <h2 className='noDataText'>
            لا يوجد بيانات
            </h2>
        </div>
    </div>
  )
}
