import React, { useEffect, useState } from 'react';
import './App.css'
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Footer from './Components/Footer/Footer';
import Navbar from './Components/Navbar/Navbar';
import Home from './pages/Home/Home';
import AboutUs from './pages/AboutUs/AboutUs';
import LatestNews from './pages/LatestNews/LatestNews';
import MyGallery from './pages/Gallery/Gallery';
import Ourwork from './pages/Ourwork/Ourwork';
import ProjectDetails from './pages/ProjectDetails/ProjectDetails';
import Contactus from './pages/Contactus/Contactus';
import NewDetails from './pages/NewDetails/NewDetails';
import { getWebsiteSettings } from './api/settings';
import { apiHost } from './config/config';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function App() {
  const [websiteSettings, setwebsiteSettings] = useState({});

  useEffect(() => {
    getWebsiteSettings().then((res) => {
      let settings = { ...websiteSettings }
      settings = res.data.data;
      setwebsiteSettings(settings);
    })
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`arabicWrapper`}>
      {websiteSettings &&
        <HelmetProvider>
          <Helmet>
            <title>{`${websiteSettings?.title ?? 'مجموعة ياسر المحمادي  للمقاولات'} | ${websiteSettings?.tag_line ?? ''}`}</title>
            <meta name="description" content={websiteSettings && websiteSettings.meta_description} />
            <meta name="keywords" content={websiteSettings && websiteSettings.meta_keywords} />
            { websiteSettings.favicon ? 
            <link rel="icon" href={`${apiHost}/${websiteSettings && websiteSettings.favicon}`} />
            :  
            <link rel="icon" href={`../public/logo.png`} />
            }
          </Helmet>
        </HelmetProvider>
        }
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path={``} element={<Home />} />
          <Route path={`/about-us`} element={<AboutUs />} />
          <Route path={`/our-work/*`} element={<Ourwork />} />
          <Route path={`our_work/:slug`} element={<ProjectDetails />} />
          <Route path={`/latest-news/*`} element={<LatestNews />} />
          <Route path={`latest-news/:slug`} element={<NewDetails />} />
          <Route path={`/gallery`} element={<MyGallery />} />
          <Route path={`/contact_us`} element={<Contactus />} />
          {/* <Route path={`/participants`} element={<Participants />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  )
}